<template>
    <!-- 店铺模块 首页 移动端-->
    <div class="shopMobileBox-container" ref="containerSide">
      <div class="shopMobileBox-containerBox" ref="containerBox">
        <!-- 移动端 -->
        <div class="shopMobileBox">
          <div class="headerBox">
            <!-- 简易小版 -->
            <div v-if="!isBigShow" class="headerBox_smallBox">
                <div class="smallBox_logo">
                    <img :src="companyInfo.logoImg" alt="" class="smallBox_logo_img">
                </div>
              
              <div class="smallBox_info">
                <div class="smallBox_info_name" @click="isBigShow = true">
                  <span>{{ ['cn','zh'].includes(languageType) ? companyInfo.name : companyInfo.nameEn }}</span>
                  <img class="info_name_img" src="../../../assets/common/sign_down.png" alt="">
                </div>
                <div class="smallBox_info_con">
                  <div class="info_con_item" v-for="item,i in companyInfo.showInfoList" :key="i">
                    <img class="info_con_item_img" :src="item.img" alt="">
                    <span>{{ ['cn','zh'].includes(languageType) ? item.text : item.textEn }}</span>
                  </div>
                </div>
              </div>
              <img @click="navTranslate()" class="smallBox_langIcon" :src="['cn','zh'].includes(languageType) ? langEn : langZh" alt="">
            </div>
            <!-- 放大版 -->
            <div v-else class="headerBox_bigBox">
              <div class="bigBox_headerBox">
                <img class="bigBox_headerBox_bgImg" :src="companyInfo.bgImg" alt="">
                <div class="bigBox_headerBox_logo">
                    <img class="bigBox_logo" :src="companyInfo.logoImg" alt="">
                </div>
                <div class="bigBox_headerBox_langBox" @click="navTranslate()">
                  <img class="headerBox_langBox_img" :src="['cn','zh'].includes(languageType) ? langEn : langZh" alt="">
                </div>
              </div>
              <div class="bigBox_name" @click="isBigShow = false">
                <span>{{ ['cn','zh'].includes(languageType) ? companyInfo.name : companyInfo.nameEn }}</span>
                <img class="bigBox_name_img" src="../../../assets/common/sign_up.png" alt="">
              </div>
              <div class="bigBox_info">
                <div class="bigBox_info_item" v-for="item,i in companyInfo.showInfoList" :key="i">
                    <img class="info_item_img" :src="item.img" alt="">
                    <span>{{ ['cn','zh'].includes(languageType) ? item.text : item.textEn }}</span>
                  </div>
              </div>
              <div class="bigBox_desc">
                <!-- 规则未定义，内容暂时写死，图片代替 -->
                <img v-if="['cn','zh'].includes(languageType)" class="bigBox_desc_img" src="../../../assets/shop/shopMobileDesc.png" alt="">
                <img v-else class="bigBox_desc_img" src="../../../assets/shop/shopMobileDescEn.png" alt="">
                <!-- <div class="bigbox_desc_box">
                  <p class="bigbox_desc_box_title">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.title : companyInfo.desc.titleEn }}</p>
                  <p class="bigbox_desc_box_item">
                    <span class="desc_box_item_b">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.offer : companyInfo.desc.offerEn }}</span>
                    <span class="desc_box_item_n">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.offerText : companyInfo.desc.offerTextEn }}</span>
                  </p>
                  <p class="bigbox_desc_box_item">
                    <span class="desc_box_item_b">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.empower : companyInfo.desc.empowerEn }}</span>
                    <span class="desc_box_item_n">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.empowerText : companyInfo.desc.empowerTextEn }}</span>
                  </p>
                  <p class="bigbox_desc_box_end">{{ ['cn','zh'].includes(languageType) ? companyInfo.desc.end : companyInfo.desc.endEn }}</p>
                </div> -->
              </div>
            </div>
          </div>

          <!-- 轮播 -->
           <div class="swipeBox" v-if="bannerData?.length">
                <van-swipe :autoplay="3000" :height="180" >
                    <van-swipe-item v-for="(item,i) in bannerData" :key="i" :default="defaultBannerImg">
                        <div class="swipeBox_item">
                            <img class="swipeBox_item_img" :src="item?.image" v-imgerror="defaultBannerImg" alt="">
                            <div class="swipeBox_item_con">
                                <div class="swipeBox_item_conBox">
                                    <div class="item_con_name"> {{ item.projectName }}</div>
                                    <div class="item_con_info">
                                        <div class="item_con_type">{{ item?.typeName }}</div>
                                        <p class="item_con_time">{{ item.episode }}eps x {{ item.length }}mins</p>
                                    </div>
                                </div>
                            </div>
                            <div @click="handleDetail(item)" class="swipeBox_item_btn">{{ $t('home_banner_detail') }}</div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
           </div>
          

          <!-- 列表 -->
          <div class="listBox">
            <div class="listBox_title">{{ $t('shop_index_list_title') }}</div>
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common_list_finished_text')"
                @load="onLoad"
                >
                <van-cell
                    v-for="(item,i) in listData"
                    :key="i">
                    <div class="listBox_itemBox" @click="handleGoSaloon(item)">
                        <div class="listBox_itemBox_imgs">
                            <img class="itemBox_imgs_img" v-for="(ele,e) in item.fileIdList" :key="e" :src="ele" alt="">
                        </div>
                        <div class="listBox_itemBox_con">
                            <p class="itemBox_con_text">{{ ['cn','zh'].includes(languageType)?item.subjectName:item.subjectNameEn }}</p>
                            <img class="itemBox_con_btn" src="../../../assets/common/sign_right.png" alt="">
                        </div>
                    </div>
                </van-cell>
            </van-list>
          </div>
        </div>

      </div>
      <div class="common_footer">
          <img class="common_footer_img" src="../../../assets/common/mobile_footer.png" alt="">
      </div>
    </div>
</template>

<script lang="jsx">
import { getBannerApi, getSubjectListApi, getShopDataApi } from '@/api/shopApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'

import { mapState } from 'vuex';

export default {
name: 'shopMobile',
data(){
  return {
      defaultBannerImg: require('../../../assets/shop/bannerBg.jpg'), // banner默认底图
      heightNum: '100vh',
      langZh: require('../../../assets/common/icon/icon_cn.png'), // lang切换icon
      langEn: require('../../../assets/common/icon/icon_en.png'), // lang切换icon
      headImg: require('../../../assets/common/icon/icon_head_defalut.png'), // 未登录
      headImgLogined: require('../../../assets/common/icon/icon_head_logined.png'), // 已登录
      shopInfo: {}, // 店铺信息
      bmsUserId: '', //
      bmsUserIdEncrypt: 0,
      sourceType: 1, // 来源类型
      shopId: "8D3E8E0CF883C4E99329AF8A29300AB6", // 当前店铺id
     // 轮播数据
      bannerData: [
        {
            id: 1,
            name: '三生三世',nameEn: '三生三世',
            typeName: '电视剧',
            img: {
            imgUrl: require('@/assets/home/collect-select.jpg')
            },
            episode: '23',
            length: '54'
        },
      ],
      pageNum: 1,
      pageSize: 10000,
      total: 0,
      languageType: '', // 中英语言类型
      isBigShow: true, // 店铺公司信息 是否放大展示，默认放大
      // 列表数据
      listData: [],
      // 列表加载状态
      loading: false,
      // 列表加载完成状态
      finished: false,

      // 店铺信息【目前写死的】
      companyInfo: {
        bgImg: require('../../../assets/shop/shopIndex_bg.jpg'),
        logoImg: '',
        name: '杭州九样文化传媒有限公司',
        nameEn: 'Hangzhou IX Media Co., Ltd.',
        showInfoList: [
          {
            id: 1,
            img: require('../../../assets/shop/company_delighted.png'),
            text: '喜悦',
            textEn: 'Delighted'
          },
          {
            id: 2,
            img: require('../../../assets/shop/company_trustworthy.png'),
            text: '信赖',
            textEn: 'Trustworthy'
          },
          {
            id: 3,
            img: require('../../../assets/shop/company_equal.png'),
            text: '平等',
            textEn: 'Equal'
          },
        ],
        desc: {
          title: '杭州九样文化传媒有限公司',
          titleEn: 'Hangzhou IX Media Co., Ltd.',
          offer: '',
          offerEn: "We offer",
          offerText: '',
          offerTextEn: "extensive genres of content for globally diverse broadcastings and platforms. We're driven by innovation and constantly refining our strategies to enhance the merit of outstanding content on the world stage.",
          empower: '',
          empowerEn: ' We empower ',
          empowerText: '',
          empowerTextEn: 'global buyers and sellers with efficient solutions for cross-cultural distribution, enriching global experiences with diverse and accessible content.',
          end: '',
          endEn: 'Partner with IX Media for a borderless content journey.',
        }
      }
  }
},
components: {
  
},
computed:{
  ...mapState('auth', ['isLoggedIn']),
  // 筛选按钮 文案
  fiflterBtnText(){
      if(this.languageType === 'zh' ) {
          if(this.fiflterBtnStatus) {
              return '收起'
          }else {
              return '展开'
          }
      }else if(this.languageType === 'en' ) {
          if(this.fiflterBtnStatus) {
              return 'Fold'
          }else {
              return 'Unfold'
          }
      }
  }
},
methods:{
  // 跳转片库接待室页面
  handleGoSaloon(data) {
      console.log('跳转片库接待室页面', data)
      this.$router.push({
          name: 'shopSaloon',
          query: {
              subject_id: data.subjectId,
              shop_id: this.shopId,
              title: data.subjectName,
              titleEn: data.subjectNameEn,
              // type: this.sourceType,
              bms_user_id: this.bmsUserId,
              bms_user_enid: this.bmsUserIdEncrypt,
              showBig: this.isBigShow
          }
      })
  },
  // 跳转详情
  handleDetail(data) {
      console.log('跳转详情', data)
      this.$router.push({
          name: 'shopDramaDetail',
          query: {
              type: 7, // 7 同3扫店铺二维码任何位置跳转至详情页，但需返回上一级页面
              drama_id: data.interlibContentId,
              bms_user_id: this.bmsUserId,
              bms_user_enid: this.bmsUserIdEncrypt,
              showBig: this.isBigShow
          }
      })
  },

  // 获取店铺信息
  getShopData() {
    let getData = {
      shop_id: this.shopId
    }
    getShopDataApi(getData).then((res)=>{
      console.log('获取店铺信息', res)
      if(res.data.code === 200) {
        this.companyInfo.logoImg = res.data.data.logo || require('../../../assets/shop/shopLogo.png')
        // console.log('获取店铺信息')
      }
    })
    .catch(err=>{
      console.log(err)
    })
  },

  // 列表加载
  onLoad() {
      this.getListData();
  },
  //获取列表数据
  getListData () {
      let postData = {
          // size: this.pageSize,
          // index: this.pageNum,
          language: this.languageType
      }
      console.log('postData',postData)
      getSubjectListApi(postData)
      .then(async (res) => {
          console.log('shop首页list',res)
          if(res.data.code === 200) {
            let _res = res.data.data || []
            // this.total = res.data.total || 3
            
            //进行判断
            // if(this.total <= this.pageSize){
            //     this.listData = res.data.data || []
            //     // console.log(this.listData);
            // } else {
            //     this.pageNum++;
            //     let arr = res.data.data || [];
            //     this.listData = this.listData.concat(arr);
            // };

            _res.forEach((ele,e)=>{
                if(ele.fileIdList && ele.fileIdList.length) {
                  ele.fileIdList.forEach((val,v)=>{
                    getPresignedUrlApi(val)
                        .then( (res) => {
                            // console.log('获取图片地址',res)
                            if (res.data.code === 200) {
                              let url = res.data?.data?.url || require('@/assets/nav/mine-login-default.jpg')
                              this.$set(ele.fileIdList,v,url)
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                  })
                    
                }
            })
            this.listData = _res
            console.log('this.listData', this.listData)
            // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            // if (this.listData.length >= this.total) {
                this.finished = true;//结束，显示我也是有底线的
            // };

          }
      })
      .catch((error) => {
        console.error(error)
      })
  },
//   获取banner数据
  getBannerData() {
    let getData = {
      language: this.languageType,
      "shop_id": this.shopId,//店铺id
    }
    console.log('getData',getData)
    getBannerApi(getData)
    .then( (res) => {
        console.log('bannerData',res)
        if(res.data.code === 200) {
            this.bannerData = res.data.data || []
        }
    })
    .catch((error) => {
        console.error(error)
    })
  },
handleScroll() {
  let scrollPosition = window.scrollY; // 获取当前滚动位置
  if (scrollPosition >= 330) {
    console.log('已滚动到距离顶部330像素的位置');
    this.isBigShow = false;
  }
},
  //中英文切换
  navTranslate () {
  console.log("中英文切换:", this.languageType);
  if (this.languageType == 'en') {
    localStorage.setItem('locationLanguageType', 'zh')
    this.languageType = 'zh'
    this.$i18n.locale = 'zh';
  } else {
    localStorage.setItem('locationLanguageType', 'en')
    this.languageType = 'en'
    this.$i18n.locale = 'en';
  }
  const location_href = window.location.href;
  if (location_href.includes('/cn')) {
    let modifiedHref = location_href.replace('/cn', '/' + 'en');
    window.location.href = modifiedHref;
  } else if (location_href.includes('/zh')) {
    let modifiedHref = location_href.replace('/zh', '/' + 'en');
    window.location.href = modifiedHref;
  } else {
    let modifiedHref = location_href.replace('/en', '/' + 'cn');
    window.location.href = modifiedHref;
  }
},
  // 获取当前语言状态
  reloadLocationLanguageData () {
    // 检查本地存储中的语言类型
    const language = localStorage.getItem('locationLanguageType')
      console.log(language)
    if (language == 'zh') {
      this.languageType = 'zh'
      this.$i18n.locale = 'zh';
    } else {
      localStorage.setItem('locationLanguageType', 'en')
      this.languageType = 'en'
      this.$i18n.locale = 'en';
    }
    console.log(this.languageType)
  },
  getWinHeight() {
    this.heightNum = window.innerHeight || '100vh'
    console.log(this.heightNum)
    alert(this.heightNum)
    this.$refs.containerSide.style.height = this.heightNum;
    this.$refs.containerBox.style.height = `calc( ${this.heightNum} - 34px )`;
  },
},
mounted() {
  this.getBannerData()
  this.getShopData()
  this.onLoad()
  window.addEventListener('scroll', this.handleScroll);
  // window.addEventListener('load', this.getWinHeight);

  this.$refs.containerSide.style.height = this.heightNum;
  this.$refs.containerBox.style.height = `calc( ${this.heightNum} - 34px )`;
  
},
created () {
  if(this.$route.query?.shop_id) {
    this.shopId = this.$route.query?.shop_id
  }
  if(this.$route.query?.type) {
    this.sourceType = this.$route.query?.type
  }
  if(this.$route.query.showBig != undefined) {
    this.isBigShow = this.$route.query.showBig
  }
  if(this.$route.query?.bms_user_id) {
    this.bmsUserId = this.$route.query.bms_user_id
  }
  if(this.$route.query?.bms_user_enid) {
    this.bmsUserIdEncrypt = this.$route.query.bms_user_enid
  }
  this.reloadLocationLanguageData()
  
  
  this.heightNum = window.innerHeight || '100vh'
  // console.log(this.heightNum)
  // alert(this.heightNum)
},
destroyed() {
  window.removeEventListener('scroll', this.handleScroll);
  // window.removeEventListener('resize', this.getWinHeight);
},
}
</script>

<style lang="less" scoped>
div,p,ul,ol,li,span {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.shopMobileBox-container {
  font-family: Source Han Sans SC-Normal;
  width: 100vw;
  height: 100vh;
  color: #fff;
  background: url('../../../assets/common/mobile_shop_bg.png');
  background-size: 100vw 100vh;
  // background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
  padding-bottom: 34px;
  position: relative;
  .shopMobileBox-containerBox {
    height: calc( 100vh - 34px );
    overflow-y: scroll;
    padding: 0 22px;
    position: relative;
  }
  .headerBox{
    .headerBox_smallBox {
      display: flex;
      align-items: center;
      padding-top: 23px;
      position: relative;
      .smallBox_logo {
        width: 50px;
        height: 50px;
        border-radius: 14px;
        background: #FFFFFF;
        margin-right: 10px;
        padding: 10px 6px;
        .smallBox_logo_img {
            width: 100%;
            height: 100%;
        }
      }
      .smallBox_info {
        .smallBox_info_name {
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          .info_name_img {
            width: 8px;
            height: 6px;
            margin-left: 6px;
            vertical-align: middle;
          }
        }
        .smallBox_info_con {
          display: flex;
          align-items: center;
          .info_con_item {
            font-size: 10px;
            line-height: 14px;
            border-radius: 14px;
            background: #13343F;
            box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
            color: rgba(255, 255, 255, 0.7);
            padding: 0 6px;
            margin-right: 9px;
            .info_con_item_img {
              width: 8px;
              height: 8px;
              margin-right: 3px;
            }
          }
        }
      }
      .smallBox_langIcon {
        width: 21px;
        height: 19px;
        position: absolute;
        top: 27px;
        right: 0;
      }
    }
    .headerBox_bigBox {
      position: relative;
      width: 100vw;
      margin-left: -22px;
      .bigBox_headerBox {
        position: relative;
      }
      .bigBox_headerBox_bgImg {
        width: 100%;
        height: 190px;
        position: relative;
      }
      .bigBox_headerBox_logo {
        display: block;
        width: 79px;
        height: 79px;
        border-radius: 18px;
        background: #FFFFFF;
        margin: -58px auto 0;
        padding: 16px 8px;
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        z-index: 99;
        .bigBox_logo {
            width: 100%;
            height: 100%;
        }
      }
      .bigBox_headerBox_langBox {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        .headerBox_langBox_img {
          width: 21px;
          height: 19px;
        }
      }
    }
    .bigBox_name {
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      margin: 29px auto 5px;
      .bigBox_name_img {
        width: 8px;
        height: 6px;
        margin-left: 6px;
        vertical-align: middle;
      }
    }
    .bigBox_info {
        padding: 18px 0 12px;
        border: 1px solid rgba(155, 155, 155, 0.7);
        margin: 16px 20px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        .bigBox_info_item {
            width: calc( 100% / 3 );
            text-align: center;
            font-size: 12px;
            line-height: 18px;
            position: relative;
            &::after {
                content: '';
                width: 1px;
                height: 14px;
                position: absolute;
                right: 0;
                bottom: 12px;
                background: #9B9B9B;
            }
            
            .info_item_img {
                width: 18px;
                height: 18px;
                display: block;
                margin: 0 auto 4px;
            }
        }
        .bigBox_info_item:nth-last-child(1)::after{
            display: none;
        }
    }
    .bigBox_desc {
        padding: 20px 19px 17px 22px;
        border: 1px solid rgba(155, 155, 155, 0.7);
        margin: 16px 20px;
        border-radius: 14px;
        .bigBox_desc_img {
            width: 100%;
        }
    }
  }
  .swipeBox {
    margin: 20px auto;
    border-radius: 14px;
    overflow: hidden;
    .swipeBox_item {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 14px;
        overflow: hidden;
        .swipeBox_item_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 14px;
            overflow: hidden;
        }
        .swipeBox_item_con {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 135px;
            border-radius: 0 0 14px 14px;
            background: linear-gradient(180deg, transparent 20%, #040000 100%);
            opacity: 0.9;
            padding: 18px 20px;
            .swipeBox_item_conBox {
                position: absolute;
                bottom: 18px;
                left: 20px;
            }
            .item_con_name {
                line-height: 23px;
                font-size: 16px;
                margin-bottom: 6px;
            }
            .item_con_info {
                display: flex;
                line-height: 18px;
                font-size: 12px;
                font-weight: 300;
                .item_con_type {
                    border-radius: 14px;
                    background: rgba(255, 255, 255, 0.2);
                    padding: 0 8px;
                    margin-right: 12px;
                }
                .item_con_time {
                    color: rgba(255, 255, 255, 0.7);
	
                }
            }
        }
        .swipeBox_item_btn {
            position: absolute;
            bottom: 18px;
            right: 20px;
            position: absolute;
            line-height: 16px;
            border-radius: 20px;
            padding: 2px 14px;
            font-size: 12px;
            background: linear-gradient(90deg, #FF036A 0%, #D564DE 35%, #6E2FF2 72%, #1A56FC 100%, #1A56FE 100%);
	
        }
    }
  }
  .listBox {
    margin-top: 16px;
      ::v-deep .van-cell {
          background: transparent;
          padding: 0;
          .van-cell__value {
              color: #fff;
          }
      }
      ::v-deep .van-cell:not(:last-child)::after {
          display: none;
      }
      .listBox_itemBox {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 14px;
          overflow: hidden;
          background: rgba(255, 255, 255, 0.12);
          margin: 8px 0;
          padding: 14px 18px 14px 12px;
          .listBox_itemBox_imgs {
              position: relative;
              width: 90px;
              height: 60px;
              margin-right: 26px;
              .itemBox_imgs_img {
                width: 48px;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                box-shadow: -5px 5px 18px 0px rgba(0, 0, 0, 0.7);
                &:nth-child(1) {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &:nth-child(2) {
                    position: absolute;
                    left: 18px;
                    top: 0;
                }
                &:nth-child(3) {
                    position: absolute;
                    left: 40px;
                    top: 0;
                }
              }
          }
          .listBox_itemBox_con {
            width: calc(100% - 146px);
            margin-left: 118px;
            position: absolute;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #FFFFFF;
            .itemBox_con_text {
                font-weight: 500;
                line-height: 18px;
                white-space: normal;
            }
            .itemBox_con_btn {
                width: 6px;
                height: 9px;
                margin-left: 26px;
            }
          }
      }
  }

}
.common_footer {
    width: 100vw;
    height: 34px;
    position: absolute;
    bottom: 0;
    left: 0;
    .common_footer_img {
        height: 16px;
        margin-top: 8px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}
::v-deep .van-swipe {
            border-radius: 14px;
            overflow: hidden;
    .van-swipe-item{
        border-radius: 14px;
        overflow: hidden;
    }
    .van-swipe__indicators {
        bottom: 8px;
        .van-swipe__indicator {
            width: 3px;
            height: 3px;
        }

    }
}
</style>