import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import './assets/css/font.css';

import Vuex from 'vuex';
import dialog from './store/dialog';
import auth from './store/auth';
import collectDialog from './store/collectDialog';
import deatilEra from './store/deatilEra';

import store from '@/store/index';

import VueI18n from 'vue-i18n'; // 中英切换
import en from '../src/locales/en.json'; // 英文
import zh from '../src/locales/zh.json'; // 中文

import MetaInfo from 'vue-meta-info' // 管理meta 信息
import VueClipboard from 'vue-clipboard2'; // 复制
import VueDevice from 'vue-device'; // 判断当前使用设备 - 移动端/平板/PC

Vue.use(VueClipboard);

// 注册 
Vue.use(MetaInfo)

Vue.use(VueI18n);

Vue.use(Vuex);

Vue.use(VueDevice);

//Select 解决iOS上键盘不弹窗问题
// ElementUI.Select.computed.readonly = function () {
// const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
// return !(this.filterable || this.multiple || !isIE) && !this.visible;
// };
Vue.use(ElementUI);

// 【图片地址加载失败时，隐藏图裂显示默认值】将封装的自定义指令挂载到Vue上(main.js)
// import { imgerror } from '@/directives'
import * as ALLdirectives from '@/utils/directives'
// 遍历得到的对象, 批量进行自定义指令的注册
for (const key in ALLdirectives) {
  // console.log(key, directives[key])
  Vue.directive(key, ALLdirectives[key])
}

Vue.config.productionTip = false;

Vue.prototype.$device = VueDevice; 


// 创建和挂载根实例。(在main.js里继续挂载)
// 记得要通过 router 配置参数注入路由，
// 从而让整个应用都有路由功能
 
// 解决重复点击导航时，控制台出现报错
// console.log(router)
// const VueRouterPush = router.prototype.push
// router.prototype.push = function push (to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }

const messages = {
  en,
  zh
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages
});

// vant-green 按需引入
import { List } from 'vant-green';
import { Cell } from "vant-green";
import { ImagePreview } from 'vant-green';
import { Swipe, SwipeItem } from 'vant-green';
import { Popup } from 'vant-green';
import { Field } from 'vant-green';
import { CellGroup } from 'vant-green';
import { Checkbox, CheckboxGroup } from 'vant-green';
import { Icon } from 'vant-green';
import { Toast } from 'vant-green';
import { Picker } from 'vant-green';
import { Button } from 'vant-green';

Vue.use(Button);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Swipe).use(SwipeItem);
Vue.use(ImagePreview);
Vue.use(Cell);
Vue.use(List);

Vue.prototype.$ImagePreview = ImagePreview

// 在应用程序初始化时调用 getUserInfo action
store.dispatch('auth/getUserInfo')
  .then(() => {
    new Vue({
    router,
    store,
    i18n, // 将 i18n 作为 Vue 实例的选项
    render: h => h(App),
    }).$mount('#app');  
});