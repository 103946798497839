<template>
  <!-- 电子名片页面 -->
  <div class="main-container">
    <div class="container"
         v-if="cardDetailData"
         @touchstart="handleTouchStart"
         @touchmove="handleTouchMove"
         @touchend="handleTouchEnd">
      <!-- <div class="bottom-gradient"
           :style="{background: currentGradient}"></div> -->
      <!-- <transition name="background-fade"> -->
      <div class="bottom-gradient"></div>
      <!-- </transition> -->
      <!-- <div class="backgrounds">
        <div class="background"
             v-for="(gradient, index) in gradients"
             :key="index"
             :style="{ opacity: backgroundOpacity(index), background: gradient }"></div>
      </div> -->
      <div v-for="(background, index) in backgrounds"
           :key="'page-' + index"
           :style="pageStyle(index)"
           class="page">
        <!-- <transition name="content-fade"
                    mode="out-in"> -->
        <div v-if="currentPage === index"
             :class="index === 0 ? 'content-page-1' : (index === 1 ? 'content-page-2' : (index === 2 ? 'content-page-3' :'content-page-1'))">
          <!-- 第一页 -->
          <div v-if="index === 0">
            <div class="page-one-container">
              <!-- <div class="inner-content">
              </div> -->
              <div class="inner-bottom-content">
              </div>
              <div class="page-one-top-container">
                <!-- 语言切换 -->
                <div class="card-info-translate-container">
                  <div></div>
                  <div class="card-info-translate"
                       @click="translateClick">
                    <img :src="languageType === 'en' ? require('@/assets/card/card_info_translate_cn.png') : require('@/assets/card/card_info_translate_en.png')"
                         alt="">
                  </div>
                </div>
                <!-- 头像 -->
                <div class="card-info-head-container">
                  <div class="card-info-pic">
                    <img :src="avatar"
                         alt="">
                    <!-- 九样Logo -->
                    <div class="card-info-jiu-yang-logo-container">
                      <div class="card-info-jiu-yang-logo">
                        <img src="@/assets/card/card_info_jiu_yang_logo.png"
                             alt="">
                      </div>
                      <!-- <div class="card-info-ix-logo">
                        <img src="@/assets/card/card_info_ix_logo.png"
                             alt="">
                      </div> -->
                      <!-- <div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- 姓名 -->
                <div class="card-info-name-container">
                  <div class="card-info-name">
                    {{ name }}
                  </div>
                  <!--<div class="card-info-detail"
                       v-show="languageType==='zh'">
                    {{ detail }}
                  </div>-->
                </div>

                <!-- 职位 -->
                <div class="card-info-detail-container-en">
                  <div class="card-info-detail-en"
                       v-if="languageType==='en'">
                    {{ detail }}
                  </div>
                  <div class="card-info-detail-en"
                       v-else>
                    {{ detail }}
                  </div>
                </div>
                <!-- 邮箱、电话、手机、公司名、地址 -->
                <div class="card-info-more-container">
                  <div class="card-info-more-left-container">
                    <img src="@/assets/card/card_info_mid_line.png"
                         alt="">
                  </div>
                  <div class="card-info-more-right-container">
                    <!-- 邮箱 -->
                    <div class="card-email-container">
                      <img class="card-info-more-container-icon"
                           src="@/assets/card/card_info_icon_email.png"
                           alt="">
                      <span>{{ email }}</span>

                    </div>
                    <!-- 电话 -->
                    <div class="card-tel-container">
                      <img class="card-info-more-container-icon"
                           src="@/assets/card/card_info_icon_tel.png"
                           alt="">
                      <span>{{ tel }}</span>
                    </div>
                    <!-- 手机 -->
                    <div class="card-phone-container">
                      <img class="card-info-more-container-icon"
                           src="@/assets/card/card_info_icon_phone.png"
                           alt="">
                      <span>{{ phone }}</span>
                    </div>
                  </div>

                </div>
                <!-- 公司地址- img -->
                <div :class="languageType === 'en' ? 'card-address-img-container-en' : 'card-address-img-container-cn'">
                  <img class="card-info-more-container-address-icon"
                       src="@/assets/card/card_info_icon_location.png"
                       alt="">
                </div>
                <!-- 公司名 -->
                <div class="card-info-company-container">
                  <span class="card-info-company">
                    {{ company }}
                  </span>
                </div>
                <!-- 公司地址 -->
                <div class="card-address-container">
                  <span>{{ address }}</span>
                </div>
                <!-- 操作按钮 -->
                <div :class="languageType === 'en' ? 'card-tools-en' : 'card-tools-cn'">

                  <div class="card-tools-export"
                       @click="cardToolsExport">
                    <img src="@/assets/card/card_info_download.png"
                         alt="">
                    <!-- <span> {{ $t('card_info_export_excel') }} </span> -->
                  </div>
                  <div class="card-tools-save"
                       @click="cardToolsSave"
                       v-if="deviceType == 'iOS'">
                    <div>
                      <span> {{ $t('card_info_add_contacts') }} </span>
                    </div>
                    <div>
                      <img src="@/assets/card/card_info_add_jiahao.png"
                           alt="">
                    </div>

                  </div>
                  <div class="card-tools-save"
                       @click="cardDownloadContact"
                       v-else>
                    <div>
                      <span> {{ $t('card_info_download_contact') }} </span>
                    </div>
                    <div>
                      <img src="@/assets/card/card_info_add_jiahao.png"
                           alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第二页 -->
          <div v-if="index === 1">
            <div class="page-two-top-container">
              <!-- 语言切换 -->
              <div class="card-info-translate-container">
                <div></div>
                <div class="card-info-translate"
                     @click="translateClick">
                  <img :src="languageType === 'en' ? require('@/assets/card/card_info_translate_cn.png') : require('@/assets/card/card_info_translate_en.png')"
                       alt="">
                </div>
              </div>
              <div class="text-layout"
                   v-if="languageType === 'en'">
                <div class="large-title">
                  <!-- <img class="large-title-img-left"
                     src="@/assets/card/card_info_title_left.png"
                     alt=""> -->
                  <span class="highlight-cn">COMPANY PROFILE</span>
                  <!-- <img class="large-title-img-right"
                     src="@/assets/card/card_info_title_right.png"
                     alt=""> -->
                </div>
                <div class="text-line-1-en">
                  <span class="large">IX</span>
                  <span class="title-normal"> Media: Global Distributors of Diverse Content</span>
                </div>
                <div class="text-line-2-en">
                  <span class="large">W</span>
                  <span class="highlight">e offer </span>
                  <span class="detail-normal">extensive genres of content for globally diverse broadcastings and platforms. </span>
                  <span class="detail-normal">We're driven by innovation and constantly refining our strategies to enhance the merit of outstanding content on the world stage.</span>
                </div>
                <div class="text-line-3-en">
                  <span class="large">W</span>
                  <span class="highlight">e empower </span>
                  <span class="detail-normal">global buyers and sellers with efficient solutions for cross-cultural distribution, </span>
                  <span class="detail-normal">enriching global experiences with diverse and accessible content.</span>
                </div>
                <div class="text-line-4-en">
                  <span class="large">P</span>
                  <span class="highlight">artner with IX Media for a borderless content journey.</span>
                </div>
              </div>
              <div class="text-layout-cn"
                   v-else>
                <div class="large-title-cn">
                  <!-- <img class="large-title-img-left"
                       src="@/assets/card/card_info_title_left.png"
                       alt=""> -->
                  <span class="highlight-cn">公司简介</span>
                  <!-- <img class="large-title-img-right"
                       src="@/assets/card/card_info_title_right.png"
                       alt=""> -->
                </div>
                <div class="text-line-cn-title">
                  <span class="highlight-cn">九样传媒 /</span>
                </div>
                <div class="text-line-cn-detail">
                  <span class="detail-normal-cn">专注于在国际影视版权交易领域中，探索和实践更为智能高效的新视角。</span>
                </div>
                <div class="text-line-cn-title">
                  <span class="highlight-cn">我们致力于 /</span>
                </div>
                <div class="text-line-cn-detail">
                  <span class="detail-normal-cn">提供一个国际化平台，尊重每一种声音并对影视艺术深度挖掘，让每一次版权交易都成为连接不同文化、激发创意灵感的桥梁。</span>
                </div>
                <div class="text-line-cn-title">
                  <span class="highlight-cn">我们的愿景 /</span>
                </div>
                <div class="text-line-cn-detail">
                  <span class="detail-normal-cn">为全球买家卖家提供「跨境版权」发行解决方案，打破传统界限，传递美好视界。</span>
                </div>
              </div>
            </div>

          </div>
          <!-- 第三页 -->
          <div v-if="index === 2">
            <div class="card-page-three">
              <!-- 语言切换 -->
              <div class="card-info-translate-container">
                <div></div>
                <div class="card-info-translate"
                     @click="translateClick">
                  <img :src="languageType === 'en' ? require('@/assets/card/card_info_translate_cn.png') : require('@/assets/card/card_info_translate_en.png')"
                       alt="">
                </div>
              </div>
              <div class="card-info-three-page-title-container">
                <div class="card-info-three-page-large-title">
                  <!-- <img class="large-title-img-left"
                       src="@/assets/card/card_info_title_left.png"
                       alt=""> -->
                  <span class="highlight-cn">
                    {{languageType === 'en' ? 'PROJECTS ON RELEASING' : '在发项目'}}</span>
                  <!-- <img class="large-title-img-right"
                       src="@/assets/card/card_info_title_right.png"
                       alt=""> -->
                </div>
              </div>
              <swiper ref="mySwiper"
                      :options="swiperOptions"
                      @slideChange="updateCurrentSlide">
                <swiper-slide v-for="(item, index) in projectItems"
                              :key="index">
                  <div class="card-info-project-container" @click="handleGoshopDramaDetail(item)">
                    <div class="list-item-img">
                      <img :src='item.url'
                           alt="">

                      <div class="card-info-project-detail-container">
                        <div class="card-info-project-detail-type-container">
                          <span class="card-info-project-detail-container-type-text">
                            {{ languageType === 'en' ? item.typeEn : item.type }}
                          </span>
                        </div>

                        <div class="card-info-project-detail-title-genres-container">
                          <div class="card-info-project-detail-container-title">
                            {{ languageType === 'en' ? item.projectNameEn : item.projectName }}
                          </div>

                          <div class="card-info-project-detail-eps-fir-container">
                            <div class="card-info-project-detail-eps-container">
                              <div class="card-info-project-detail-eps-container-img">
                                <!-- <img src="@/assets/card/card_info_icon_time.png"
                                     alt=""> -->
                              </div>
                              <span>
                                {{ item.episode + 'eps' +  ' x ' + item.length + 'mins'}} </span>
                            </div>
                            <div>
                              <div class="card-info-project-detail-container-genres-right">
                                <!-- <img src="@/assets/card/card_info_fair.png"
                                     alt=""> -->
                              </div>
                            </div>
                          </div>

                          <div class="card-info-project-detail-container-genres">
                            <!-- <div class="card-info-project-detail-container-genres-left">
                              <span class="card-info-project-detail-container-type">
                                {{ languageType === 'en' ? item.genresEn : item.genres }}
                              </span>
                            </div> -->
                            <div class="card-info-project-detail-container-genres-left">
                              <template v-if="languageType === 'en'">
                                <span class="card-info-project-detail-container-type"
                                      v-for="genre in item.genresEn.split('•')"
                                      :key="genre">
                                  {{ genre }}
                                </span>
                              </template>
                              <template v-else>
                                <span class="card-info-project-detail-container-type"
                                      v-for="genre in item.genres.split('•')"
                                      :key="genre">
                                  {{ genre }}
                                </span>
                              </template>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <div class="swiper-pagination"
                     slot="pagination">
                </div>
              </swiper>

            </div>
          </div>
        </div>
        <!-- </transition> -->
      </div>

      <div v-if="currentPage < backgrounds.length - 1"
           @click="nextPage"
           class="next-button">
        <img src="@/assets/card/card_info_next.png"
             alt="">
      </div>
    </div>
    <!-- 安卓下周联系人提示 -->
    <el-dialog :visible.sync="downloadCenterDialogVisible"
               width="70%"
               top="30vh"
               :show-close='false'
               center
               class="custom-dialog">
      <div class="custom-dialog-download-container">
        <span class="custom-dialog-download-container-title">{{ languageType === 'en' ? 'Tips' : '提示' }}</span>
        <span class="custom-dialog-download-container-detail"
              v-if="languageType === 'en' ">After downloading the name card file, open it with "Contacts"</span>
        <span class="custom-dialog-download-container-detail-cn"
              v-else>下载名片文件后，请用“通讯录”打开。</span>
        <div class="custom-dialog-download-container-confirm"
             @click="centerDialogConfirmClick">
          {{ languageType === 'en' ? 'Confirm' : '确定' }}
        </div>
      </div>
    </el-dialog>

    <!-- iOS保存联系人提示 -->
    <el-dialog :visible.sync="saveCenterDialogVisible"
               width="80%"
               top="30vh"
               :show-close='false'
               center
               class="custom-dialog">
      <div class="custom-dialog-download-container">
        <span class="custom-dialog-download-container-title">{{ languageType === 'en' ? 'Tips' : '提示' }}</span>
        <span class="custom-dialog-download-container-detail"
              v-if="languageType === 'en'">After accessing the Contact App, please go down to the end of the page, and click "Create New Contact" to save.</span>
        <span class="custom-dialog-download-container-detail-cn"
              v-else>跳转通讯录App后，下拉到底，点击“新建联系人”。</span>
        <div class="custom-dialog-download-container-confirm"
             @click="centerDialogConfirmClick">
          {{ languageType === 'en' ? 'Confirm' : '确定' }}
        </div>
      </div>
    </el-dialog>
  </div>
  <!-- <div v-else>
    <div class="rotate">
      <img class="rotate-img"
           src="@/assets/common/mobile_bg-logo.png"
           alt="" />
      <img class="rotate-img-turn"
           src="@/assets/common/mobile_turn.gif"
           alt=""
           style="opacity: 0.5;" />
      <span class="rotate-text">{{ $t('mobile_tips') }}</span>
    </div>
  </div> -->
</template>

<script>
import { cardDetailApi } from '@/api/cardApi.js'
import * as XLSX from 'xlsx';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      screenHW: window.orientation, // 判断横竖屏
      isScreen: true, // 竖屏
      translate: 'EN',
      avatar: '',
      name: '',
      detail: '',
      email: '',
      tel: '',
      phone: '',
      companyProfileContent: '',
      issuanceProjectTitle: '',
      projectItems: [],
      subject: '',
      body: '',
      languageType: "",
      deviceType: '',
      downloadCenterDialogVisible: false,
      saveCenterDialogVisible: false,
      cardDetailData: undefined,
      wechat: '',
      company: '',
      whatsapp: '',
      web: '',
      vCardContent: '',
      address: '',
      note: '',
      currentPage: 0,
      backgrounds: ['rgba(243, 244, 246, 0)', 'rgba(209, 213, 219, 0)', 'rgba(156, 163, 175, 0)'],
      gradients: [
        // 'linear-gradient(to bottom right, #76d7e3 10%, #faccdc 100%)', // 为每个页面定义不同的渐变
        'linear-gradient(300deg, #e5a1c0 0%, #faccdc 10%, #faccdc 15%, #faccdc 20%, #76d7e3 60%,#76d7e3 80%, #76d7e3 100%)',
        'linear-gradient(to right, #9ce1eb 10%, #faccdc 80%, #e5a1c0 100%)',
        'linear-gradient(135deg, #e7fbfc 5%, #e7fbfc 10%, #faccdc 40%,#e5a1c0 80%, #e5a1c0 100%)'
      ],
      touchStartY: 0,
      translateY: 0,
      opacity: 1,
      moving: false,
      swiperCurrentSlide: 0,
      swiperOptions: {
        initialSlide: 0,
        loop: true,
        touchStartPreventDefault: true,
        touchMoveStopPropagation: true,
        pagination: {
          el: '.swiper-pagination', // 分页器容器的CSS选择器
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return `${current} / ${total}`;
          },
        },
      },
    };
  },
  created () {
    this.reloadLocationLanguageData();
    this.checkDeviceType();
  },
  mounted () {
    this.screenHW = window.orientation  //保证刷新时获取状态准确
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenHW = window.orientation   // 把横竖屏的变化赋值
      })()
    }

    if (this.isMobileDevice()) {
      window.addEventListener("orientationchange", this.handleOrientationChange);
    }
    this.fetchCardDetailData()
  },
  watch: {
    screenHW: {
      immediate: true,
      handler (newval, old) {
        this.rotate()
      }

    }
  },
  beforeDestroy () {
    if (this.isMobileDevice()) {
      window.removeEventListener("orientationchange", this.handleOrientationChange);
    }
  },
  computed: {
    currentBackground () {
      return this.backgrounds[this.currentPage];
    },
    currentGradient () {
      return this.gradients[0]; // 返回当前页面对应的渐变背景
    }
  },
  methods: {
    // 跳转至 移动端 店铺单剧详情页
    handleGoshopDramaDetail(data) {
      console.log('跳转至 移动端 店铺单剧详情页', data, window.location,window.location.port);
      let id = localStorage.getItem('locationCardId')
      window.location.href = `https://marketplace.interlib.asia/${this.languageType}/shopDramaDetail?type=5&drama_id=${data.dramaId}&shop_id=${this.cardDetailData.shopId}&bms_user_id=${this.cardDetailData.bmsUserId}&bms_user_enid=${id}`
      
      // window.location.href = `http://${window.location.hostname}:${window.location.port}/${this.languageType}/shopDramaDetail?type=5&drama_id=${data.dramaId}&shop_id=${this.cardDetailData.shopId}&bms_user_id=${this.cardDetailData.bmsUserId}&bms_user_enid=${id}`

      this.$router.push({})
    },
    nextPage () {
      if (this.currentPage < this.backgrounds.length - 1) {
        this.currentPage += 1;
        this.resetTransform();
      }
    },
    previousPage () {
      if (this.currentPage > 0) {
        this.currentPage -= 1;
        this.resetTransform();
      }
    },
    handleTouchStart (event) {
      this.touchStartY = event.touches[0].clientY;
      this.moving = true;
    },
    handleTouchMove (event) {
      if (!this.moving) return;
      const touchCurrentY = event.touches[0].clientY;
      const touchDelta = touchCurrentY - this.touchStartY;
      this.translateY = touchDelta;
      this.opacity = 1 - Math.abs(touchDelta) / window.innerHeight;
    },
    handleTouchEnd (event) {
      const touchEndY = event.changedTouches[0].clientY;
      const touchDelta = touchEndY - this.touchStartY;
      this.moving = false;

      if (Math.abs(touchDelta) > 120) {
        if (touchDelta < 0 && this.currentPage < this.backgrounds.length - 1) {
          this.nextPage();
        } else if (touchDelta > 0 && this.currentPage > 0) {
          this.previousPage();
        } else {
          this.resetTransform();
        }
      } else {
        this.resetTransform();
      }
    },
    resetTransform () {
      this.translateY = 0;
      this.opacity = 1;
    },
    backgroundOpacity (index) {
      if (index === this.currentPage) {
        return this.opacity;
      } else if (index === this.currentPage + 1 || index === this.currentPage - 1) {
        return 1 - this.opacity;
      } else {
        return 0;
      }
    },
    // pageStyle (index) {
    //   if (index === this.currentPage) {
    //     return {
    //       background: this.backgrounds[index],
    //       transform: `translateY(${this.translateY}px)`,
    //       opacity: this.opacity,
    //     };
    //   } else if (index === this.currentPage + 1) {
    //     return {
    //       background: this.backgrounds[index],
    //       transform: `translateY(${window.innerHeight + this.translateY}px)`,
    //       opacity: 1 - this.opacity,
    //     };
    //   } else if (index === this.currentPage - 1) {
    //     return {
    //       background: this.backgrounds[index],
    //       transform: `translateY(${-window.innerHeight + this.translateY}px)`,
    //       opacity: 1 - this.opacity,
    //     };
    //   } else {
    //     return {
    //       background: this.backgrounds[index],
    //       transform: `translateY(${window.innerHeight}px)`,
    //       opacity: 0,
    //     };
    //   }
    // },
    pageStyle (index) {
      const commonStyle = {
        willChange: 'transform, opacity'
      };

      if (index === this.currentPage) {
        return {
          ...commonStyle,
          background: this.backgrounds[index],
          transform: `translateY(${this.translateY}px)`,
          opacity: this.opacity,
        };
      } else if (index === this.currentPage + 1) {
        return {
          ...commonStyle,
          background: this.backgrounds[index],
          transform: `translateY(${window.innerHeight + this.translateY}px)`,
          opacity: 1 - this.opacity,
        };
      } else if (index === this.currentPage - 1) {
        return {
          ...commonStyle,
          background: this.backgrounds[index],
          transform: `translateY(${-window.innerHeight + this.translateY}px)`,
          opacity: 1 - this.opacity,
        };
      } else {
        return {
          ...commonStyle,
          background: this.backgrounds[index],
          transform: `translateY(${window.innerHeight}px)`,
          opacity: 0,
        };
      }
    },
    //获取电子名片数据
    fetchCardDetailData () {
      const locationCardId = localStorage.getItem('locationCardId')
      const data = {
        userId: locationCardId,
      }
      console.log("获取电子名片数据-参数：", data);

      cardDetailApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log('res.data:', res.data.data);
            this.cardDetailData = res.data.data;
            this.projectItems = this.cardDetailData.project;
            this.avatarBase64 = this.cardDetailData.imageUrl;
            this.undateData();
          }
        })
        .catch((error) => {
          this.isFilmListLoad = true
          this.isFilmListExpire = false;
          this.isFilmListFailed = true;
        })
    },

    undateData () {
      //头像
      this.avatar = this.cardDetailData.avatar
      // 姓名 Name
      this.name = this.languageType === 'en' ? this.cardDetailData.enNickname : this.cardDetailData.nickname
      // 职位 Title
      this.detail = this.languageType === 'en' ? this.cardDetailData.enPosition : this.cardDetailData.position
      // 公司 Company
      this.company = this.languageType === 'en' ? this.cardDetailData.enCompany : this.cardDetailData.company
      // 公司简介
      this.companyProfileContent = this.languageType === 'en' ? this.cardDetailData.enIntroduction : this.cardDetailData.introduction
      // 邮箱 Email
      this.email = this.cardDetailData.email
      // 电话 Direct line
      this.tel = this.cardDetailData.tel
      // 手机 Cellphone
      this.phone = this.cardDetailData.mobile
      // 微信 Wechat
      this.wechat = this.cardDetailData.wechat
      // Whatsapp Whatsapp
      this.whatsapp = this.cardDetailData.whatsapp
      // 网址 Link
      this.web = this.cardDetailData.link
      //备注
      this.note = `${this.languageType === 'en' ? "wechat" : "微信"}:${this.wechat} \\nWhatsapp:${this.whatsapp}`
      //地址
      this.address = this.languageType === 'en' ? this.cardDetailData.enAddress : this.cardDetailData.address
    },

    checkDeviceType () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.deviceType = 'Android';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.deviceType = 'iOS';
      } else {
        this.deviceType = '其他';
      }
    },
    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    isMobileDevice () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    handleOrientationChange () {
      if (window.orientation === 90 || window.orientation === -90) {
        // 横屏展示
        this.isScreen = false
      } else {
        // 竖屏展示
        this.isScreen = true
      }
    },
    // 判断横竖屏
    rotate () {
      if (this.screenHW == 180 || this.screenHW == 0) {
        console.log('BusinessCardPage-竖屏')
        this.isScreen = true
      } else if (this.screenHW == 90 || this.screenHW == -90) {
        console.log('BusinessCardPage-横屏')
        this.isScreen = false
      }
    },
    translateClick () {
      console.log('切换语言');
      if (this.languageType == 'en') {
        localStorage.setItem('locationLanguageType', 'zh')
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
        localStorage.setItem('cardUserChangeLocationLanguageType', 'zh')
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
        localStorage.setItem('cardUserChangeLocationLanguageType', 'en')
      }
      this.undateData();
      // const location_href = window.location.href;
      // if (location_href.includes('/cn')) {
      //   let modifiedHref = location_href.replace('/cn', '/' + 'en');
      //   window.location.href = modifiedHref;
      // } else {
      //   let modifiedHref = location_href.replace('/en', '/' + 'cn');
      //   window.location.href = modifiedHref;
      // }
    },
    cardToolsExport () {
      console.log('导出Excel');

      const wsData = [
        this.languageType === 'en' ? ["Name", "Title", "Company", "Direct line", "Cellphone", "Email", "Wechat", "Whatsapp", "Link"]
          : ["姓名", "职位", "公司", "电话", "手机", "邮箱", "微信", "Whatsapp", "网址"],
        [
          this.name,
          this.detail,
          this.company,
          this.tel,
          this.phone,
          this.email,
          this.wechat,
          this.whatsapp,
          this.web
        ]
      ];
      // Create a new worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      // Create a new workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // Generate Excel file and trigger download
      XLSX.writeFile(wb, `${this.name ? this.name : 'namecard'}.xlsx`);
    },
    sendEmail () {
      console.log('发邮件');
      const mailtoLink = `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
      window.location.href = mailtoLink;
    },
    callTel () {
      console.log('打座机号');
      const telLink = `tel:${this.tel}`;
      window.location.href = telLink;
    },
    callPhone () {
      console.log('打手机号');
      const telLink = `tel:${this.phone}`;
      window.location.href = telLink;
    },
    centerDialogConfirmClick () {
      this.generateVCard();
      this.downloadCenterDialogVisible = false;
      this.saveCenterDialogVisible = false;
    },
    cardToolsSave () {
      console.log('iOS-加入通讯录');
      this.saveCenterDialogVisible = true;
    },
    cardDownloadContact () {
      console.log('Android-下载名片');
      console.log('下载通讯录');
      this.downloadCenterDialogVisible = true;
    },
    generateVCard () {
      this.vCardContent = `BEGIN:VCARD\r\nVERSION:3.0\r\nN:${this.name}\r\nORG:${this.company}\r\nTEL;TYPE=CELL;TYPE=VOICE:${this.phone}\r\nTEL;WORK;VOICE:${this.tel}\r\nEMAIL;PREF;INTERNET:${this.email}\r\nURL:${this.web}\r\nTITLE:${this.detail}\r\nADR;WORK;POSTAL:${this.removeNewlines(this.address)}\r\nPHOTO;ENCODING=b;TYPE=PNG:${this.avatarBase64}\r\nNOTE:${this.note}\r\nREV:${new Date().toISOString()}\r\nEND:VCARD\r\n`;
      const blob = new Blob([this.vCardContent], { type: 'text/x-vcard' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.name ? this.name : 'namecard'}.vcf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    removeNewlines (value) {
      if (!value) return '';
      return value.replace(/\n/g, '');
    },

    updateCurrentSlide () {
      const swiper = this.$refs.mySwiper[0].$swiper;
      this.swiperCurrentSlide = swiper.realIndex;

      this.swiperOptions = {
        initialSlide: this.swiperCurrentSlide ?? 0,
        loop: true,
        touchStartPreventDefault: false,
        touchMoveStopPropagation: true,
        pagination: {
          el: '.swiper-pagination', // 分页器容器的CSS选择器
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            return `${current} / ${total}`;
          },
        },
      };
    },
  }
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  touch-action: none; /* 禁用默认的滚动行为 */
}

.backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
}

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  will-change: opacity;
}

.background-fade-enter-active,
.background-fade-leave-active {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.background-fade-enter, .background-fade-leave-to /* .background-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: scale(1.1);
}

.content-fade-enter-active,
.content-fade-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.content-fade-enter, .content-fade-leave-to /* .content-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: scale(1);
}

.bottom-gradient {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* transition: background 1s ease; */
  /* background-color: #fff; */
  transition: background 2s ease-in-out; /* 增加过渡时间并使用更复杂的过渡效果 */
  background-image: url("@/assets/card/card_info_bg.png");
  background-size: cover; /* 使背景图覆盖整个div */
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 禁止背景图重复 */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease-in-out; /* 使用更长的过渡时间 */
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  /* transition: transform 0.5s, opacity 0.5s; */
  will-change: transform, opacity;
}

.page.transition {
  transition: transform 0.5s, opacity 0.5s; /* 仅在需要时添加 transition */
}

.content-page-1 {
  text-align: center;
  width: 80%;
  height: 70%;
  margin-bottom: 25%;
}

.content-page-2 {
  text-align: center;
  width: 80%;
  height: 70%;
  margin-bottom: 25%;
}

.content-page-3 {
  text-align: center;
  width: 84%;
  height: 70%;
  margin-bottom: 25%;
}

.next-button {
  position: absolute;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  animation: breath 2s infinite;
  /* background-color: rgba(255, 255, 255, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.next-button img {
  width: 100%;
}

@keyframes breath {
  0%,
  100% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.1);
  }
}

/* 电子名片-语言切换--Begin-- */
.card-info-translate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}

.card-info-translate {
  padding: 1px 4px 3px 4px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 50px;
}

.card-info-translate img {
  width: 42px;
  height: 42px;
}
/* 电子名片-语言切换--End-- */

.page-one-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.inner-content {
  width: 140px; /* 调整宽度 */
  height: 70px; /* 调整高度 */
  border-top-left-radius: 70px; /* 半圆角 */
  border-top-right-radius: 70px; /* 半圆角 */
  background: rgba(255, 255, 255, 0.15);
  /* box-shadow: 0 8px 32px 0 rgba(229, 161, 192, 0.37); */
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  /* border-radius: 20px; */
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
  transition: background 2s ease-in-out; /* 增加过渡时间并使用更复杂的过渡效果 */
}

.inner-bottom-content {
  width: 100%;
  height: 550px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 18px 32px 0 rgba(10, 62, 32, 0.2);
  border-radius: 15px;
  background-image: url("@/assets/card/card_info_page_bg_1.png");
  background-size: 100% 100%; /* 拉伸背景图，使其填充整个容器 */
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 禁止背景图重复 */
}

.page-one-top-container {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 540px;
  background-color: transparent;
}

/* 电子名片-头像--Begin-- */
.card-info-head-container {
  display: flex;
  /* justify-content: center; */
  /* align-content: center; */
  margin-top: -40px;
  margin-left: 45px;
}

.card-info-pic {
  width: 140px;
  height: 140px;
  position: relative;
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-info-pic img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 110px;
}
/* 电子名片-头像--End-- */

/* 电子名片-九样Logo--Begin-- */
.card-info-jiu-yang-logo-container {
  width: 200px;
  height: 70px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  z-index: 10;
  margin-right: -160px;
  margin-bottom: 2px;
}

.card-info-jiu-yang-logo {
  width: 160px;
  height: 45px;
  /* background: rgba(255, 255, 255, 0.15); */
  /* box-shadow: 0 18px 32px 0 rgba(229, 161, 192, 0.37); */
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-info-jiu-yang-logo img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  border-radius: 0px;
  margin-left: -5px;
  opacity: 0.9;
}

.card-info-ix-logo {
  width: 110px;
  height: 30px;
  border-radius: 0px;
  margin-left: -10px;
}
.card-info-ix-logo img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  object-fit: contain;
  padding: 5px;
}

/* 电子名片-九样Logo--End-- */

/* 电子名片-姓名--Begin-- */
.card-info-name-container {
  display: flex;
  align-content: center;
  margin-top: 5px;
  margin-left: 50px;
  flex-direction: row;
}

.card-info-name {
  color: #fff;
  font-size: 24px;
  font-family: Source Han Sans SC-Regular;
  line-height: 1.3;
}
/* 电子名片-姓名--End-- */

/* 电子名片-公司名--Begin-- */
.card-info-company-container {
  margin-top: 5px;
  margin-left: 50px;
  text-align: left;
}

.card-info-company {
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Regular;
  line-height: 1.3;
  text-align: left;
}
/* 电子名片-姓名--End-- */

/* 电子名片-职位--Begin-- */
.card-info-detail-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.card-info-detail {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 6px;
  margin-left: 25px;
}

.card-info-detail-container-en {
  display: flex;
  /* justify-content: center; */
  align-content: center;
  margin-left: 50px;
}

.card-info-detail-en {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 6px;
  /* margin-left: 25px; */
  text-align: left;
  line-height: 1.2;
}
/* 电子名片-职位--End-- */

/*  邮箱、电话、手机、公司名、地址--Begin-- */
.card-info-more-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 20px 10px 0 50px;
}

.card-info-more-left-container {
  width: 1.5px;
  height: 80px;
  margin-top: 10px;
}

.card-info-more-left-container img {
  width: 100%;
  height: 100%;
}

.card-info-more-right-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 20px;
  margin-top: -5px;
}

.card-info-more-container span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}

.card-info-more-container-point {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #2b2d31;
  margin-right: 15px;
  flex-shrink: 0; /* 防止被挤压 */
  margin-top: 2.5px;
}

.card-info-more-container-icon {
  width: 22px;
  margin-right: 10px;
  flex-shrink: 0; /* 防止被挤压 */
  margin-top: 2.5px;
  /* opacity: 0.7; */
}

.card-info-more-container-address-icon {
  width: 14px;
  margin-right: 15px;
  flex-shrink: 0; /* 防止被挤压 */
  margin-top: 2.5px;
  /* opacity: 0.7; */
}

.card-info-more-container-address-point {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #2b2d31;
  margin-right: 15px;
  flex-shrink: 0; /* 防止被挤压 */
  margin-top: 0px;
}

/* 邮箱 */
.card-email-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

/* 电话 */
.card-tel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

/* 手机 */
.card-phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

/* 公司名 */
.card-company-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

/* 公司地址 */
.card-address-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-left: 50px;
  margin-right: 20px;
}

.card-address-container span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  text-align: left;
}
/*  邮箱、电话、手机、公司名、地址--End-- */

.card-address-img-container-en {
  margin-top: 20px;
  margin-left: 48px;
  display: flex;
  align-items: start;
}

.card-address-img-container-cn {
  margin-top: 40px;
  margin-left: 48px;
  display: flex;
  align-items: start;
}

/* 电子名片-操作按钮--Begin-- */
.card-tools-en {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
}

.card-tools-cn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
}

.card-tools-save {
  width: 180px;
  /* height: 55px; */
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 8px 32px 0 rgba(229, 161, 192, 0.37); */
  transition: background 2s ease-in-out; /* 增加过渡时间并使用更复杂的过渡效果 */
  background-image: url("@/assets/card/card_info_add.png");
  background-size: cover; /* 使背景图覆盖整个div */
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 禁止背景图重复 */
  border-radius: 40px;
}

.card-tools-save span {
  color: rgba(255, 255, 255, 0.9);
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  margin-bottom: 5px;
}

.card-tools-save img {
  width: 14px;
  margin-left: 10px;
  margin-top: 5px;
}

.card-tools-save_cn span {
  color: #0796ad;
  font-size: 15px;
  font-family: Source Han Sans SC-Medium;
  text-align: center;
}

.card-tools-export {
  width: 60px;
  /* height: 55px; */
  margin-right: 5px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  /* margin-bottom: 10px; */
  /* box-shadow: 0 8px 32px 0 rgba(229, 161, 192, 0.37); */
}

.card-tools-export img {
  width: 100%;
  height: 100%;
}

.card-tools-export span {
  color: #ad6fbc;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  text-align: center;
}
/* 电子名片-操作按钮--End-- */

.page-two-top-container {
  width: 100%;
  height: 550px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 18px 32px 0 rgba(10, 62, 32, 0.2);
  border-radius: 15px;
  background-image: url("@/assets/card/card_info_page_bg_1.png");
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 禁止背景图重复 */
  background-size: 100% 100%; /* 拉伸背景图，使其填充整个容器 */
}

.text-layout {
  padding: 20px;
  height: 550px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

h1 {
  margin-bottom: 20px;
}

div {
  line-height: 1.6;
}

.large {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
  font-family: Source Han Sans SC-Regular;
}

.large-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
}

.large-title img {
  width: 10px;
}

.large-title-img-left {
  margin-top: -10px;
}

.large-title-img-right {
  margin-bottom: -10px;
}

.highlight {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.title-normal {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
}

.detail-normal {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}

.highlight-cn {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-family: Source Han Sans SC-Regular;
  line-height: 1.5;
}

.detail-normal-cn {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 2;
}

.text-layout-cn {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.large-title-cn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: 50px;
}

.text-line-cn-title {
  margin-bottom: 10px;
}

.text-line-cn-detail {
  margin-bottom: 40px;
}

.card-page-three {
  margin: 0 10px;
  height: 550px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 18px 32px 0 rgba(10, 62, 32, 0.2);
  border-radius: 15px;
  background-image: url("@/assets/card/card_info_page_bg_1.png");
  background-size: 100% 100%; /* 拉伸背景图，使其填充整个容器 */
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 禁止背景图重复 */
}

.card-info-three-page-large-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -55px;
}

.text-line-1-en {
  margin-top: 10px;
}
.text-line-2-en {
  margin-top: 10px;
}
.text-line-3-en {
  margin-top: 10px;
}
.text-line-4-en {
  margin-top: 10px;
}

.text-line-cn {
  margin-top: 20px;
}

.card-info-three-page-title-container {
  margin-top: 15px;
  margin-bottom: 20px;
}

.card-info-project-container {
  height: 480px;
  margin: 0 20px;
}

.list-item-img {
  width: 100%;
  height: 445px;
  position: relative;
}

.list-item-img img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.list-item-title {
  margin-top: 5px;
  color: #2b2d31;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
  text-align: left;
}

.list-item-detail {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #9b9b9b;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
}

.card-info-project-detail-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  border-bottom-left-radius: 16px; /* 半圆角 */
  border-bottom-right-radius: 16px; /* 半圆角 */
  padding-top: 50px;
}

.card-info-project-detail-type-container {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 15px;
}

.card-info-project-detail-container-type-text {
  padding: 1px 6px;
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Medium;
  background: rgba(255, 255, 255, 0.05);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.card-info-project-detail-container-genres {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  justify-content: space-between;
}

.card-info-project-detail-container-genres-right {
  width: 35px;
  height: 10px;
  margin-bottom: 12px;
}

.card-info-project-detail-container-genres-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-info-project-detail-container-genres-left {
  margin-top: 3px;
}

.card-info-project-detail-container-type {
  padding: 0px 5px 2px 5px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  margin-right: 3px;
}

.card-info-project-detail-container-type-eps {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  margin-bottom: 5px;
}

.card-info-project-detail-container-type {
  margin-right: 5px;
}

.card-info-project-detail-container-eps {
  margin-left: 5px;
}

.card-info-project-detail-container-title {
  margin-left: 15px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 5px;
  text-align: left;
  white-space: normal; /* 允许文本换行 */
  word-wrap: break-word; /* 在必要时换行 */
  line-height: 1.4;
  margin-top: 10px;
}

.card-info-project-detail-title-genres-container {
  border-bottom-left-radius: 16px; /* 半圆角 */
  border-bottom-right-radius: 16px; /* 半圆角 */
  background: rgba(255, 255, 255, 0.05);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.card-info-project-detail-eps-fir-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
}

.card-info-project-detail-eps-container {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  margin-bottom: 3px;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 15px;
}

.card-info-project-detail-eps-container-img {
  width: 0px;
  height: 0px;
  margin-right: 0px;
}

.card-info-project-detail-eps-container-img img {
  width: 100%;
  object-fit: cover;
  margin-top: 6px;
}

img {
  -webkit-touch-callout: none; /* Disable the callout menu on long press */
  -webkit-user-select: none; /* Disable text selection */
  -khtml-user-select: none; /* For Konqueror browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: transparent; /* Disable highlight on tap */
}

.rotate {
  background-color: #2b2d31;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  background-image: url("@/assets/common/mobile_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.rotate-img {
  align-items: center;
  width: 110px;
  /* box-shadow: 10px 13px 18px rgba(0, 0, 0, 0.3); */
}

.rotate-img-turn {
  margin-top: 130px;
  align-items: center;
  width: 110px;
}

.rotate-text {
  margin-top: 0px;
  font-size: 14px;
  color: #fff;
  font-family: Source Han Sans SC-Normal;
  text-align: center;
}

::v-deep .custom-dialog .el-dialog {
  border-radius: 16px !important;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  box-shadow: none !important;
}

::v-deep .el-dialog__wrapper {
  background: none !important;
  box-shadow: none !important;
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}

.custom-dialog-download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-dialog-download-container-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  margin-top: -20px;
}

.custom-dialog-download-container-detail {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  word-break: keep-all;
  white-space: normal;
}

.custom-dialog-download-container-detail-cn {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 20px;
  margin-left: 8px;
  margin-right: 8px;
  white-space: normal;
  text-align: left;
}

.custom-dialog-download-container-confirm {
  padding: 2px 20px 4px 20px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 8px;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.05);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}
</style>

<style>
.swiper-pagination {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Source Han Sans SC-Normal;
  text-align: center;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  width: 5px !important;
  height: 5px !important;
  opacity: 0.4 !important;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
  transform: scale(1.2) !important;
  opacity: 0.9 !important;
}
</style>